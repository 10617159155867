export const translations = {
  en: {
    // General
    dashboard: "Dashboard",
    customers: "Customers",
    customersDashboard: "Customers Dashboard",
    notifications: "Notifications",
    allScales: "All Scales",
    home: "Home",
    currentStatus: "Current Status",
    thresholds: "Thresholds",
    weightHistory: "Weight History",
    save: "Save Changes",
    close: "Close",
    from: "From",
    to: "To",
    productsDashboard: "Products Dashboard",
    productStatus: "Product Status",
    upperThreshold: "Upper Threshold",
    lowerThreshold: "Lower Threshold",
    lastUpdated: "Last Updated",
    noProducts: "No products found",
    failedToFetchProducts: "Failed to fetch products data",

    // Landing Page
    welcomeTitle: "Quntifyz - Scale Monitor System",
    welcomeSubtitle: "Select a module to begin",
    dashboardDesc: "View all scales and their current status in a comprehensive dashboard.",
    customersDesc: "Manage and monitor customer scales, products, and inventory levels.",
    notificationsDesc: "Check alerts and notifications for low inventory levels.",
    allScalesDesc: "Monitor and manage all scales across all customers in a single view.",

    // Dashboard
    scaleMonitorDashboard: "Scale Monitor Dashboard",
    latestWeight: "Latest Weight",
    noData: "No data available",

    // Notifications
    scaleAlerts: "Scale Alerts",
    noAlerts: "No alerts to display",
    runningLowMessage: "I am running out of",
    productLeft: "I have only ",
    pleaseResupply: "Please send me coffee!",

    // Thresholds
    thresholdSettings: "Threshold Settings",
    upperThresholdKg: "Upper Threshold (kg)",
    lowerThresholdKg: "Lower Threshold (kg)",
    upperNotification: "Upper Threshold Notification",
    lowerNotification: "Lower Threshold Notification",
    whatsappNumber: "WhatsApp Number",
    messageWhenExceeds: "Message when weight exceeds upper threshold",
    messageWhenBelow: "Message when weight falls below lower threshold",
    signIn: "Sign In",
    signOut: "Sign Out",
    register: "Register",
    forgotPassword: "Forgot Password?",
    email: "Email",
    password: "Password",
    confirmPassword: "Confirm Password",
    name: "Name",
    createAccount: "Create Account",
    alreadyHaveAccount: "Already have an account? Sign in",
    dontHaveAccount: "Don't have an account? Register",
    verifyEmail: "Verify Email",
    verificationCode: "Verification Code",
    verify: "Verify",
    resendCode: "Resend Code",
    verificationEmailSent: "We've sent a verification code to your email",
    passwordsDontMatch: "Passwords don't match",
    passwordRequirements: "Password must be at least 8 characters",
    signInError: "Failed to sign in",
    signUpError: "Failed to create account",
    verificationError: "Failed to verify email",
    resetPassword: "Reset Password",
    newPassword: "New Password",
    resetPasswordError: "Failed to reset password",
    passwordResetSuccess: "Password reset successful",
    welcomeBack: "Welcome back",
    welcome: "Welcome to Scalez",
    // Customer Dashboard
    customerOverview: "Customer Overview",
    backToCustomers: "Back to Customers",
    // Menu
    menuTitle: "Quantifyz",
    menuSubtitle: "Weight It",
    version: "Version",
    copyright: "© 2024 Scale Monitor",

    // Menu Descriptions
    homeDesc: "Return to home page",
    // Landing Page
    userName: "User",
    welcomeMessage: "{time}, {name}",

    timeGreetings: {
      morning: "Good Morning",
      afternoon: "Good Afternoon",
      evening: "Good Evening"
    },

    stats: {
      activeScales: "Active Scales",
      customers: "Active Customers",
      alerts: "Active Alerts",
      today: "today",
      thisWeek: "this week",
      thisMonth: "this month"
    },

    quickActions: {
      title: "Quick Actions",
      items: [
        "Add New Scale",
        "Generate Report",
        "View Notifications"
      ]
    },

    unknownCustomer: "Unknown Customer",
    saving: "Saving...",
    saveChanges: "Save Changes",
    weight: "Weight",
    customer: "Customer",
    productName: "Product Name",

    // Graph
    time: "Time",

    // Notifications
    notificationSettings: "Notification Settings",
    phoneNumber: "Phone Number",
    message: "Message",
    upperThresholdNotification: "Upper Threshold Notification",
    lowerThresholdNotification: "Lower Threshold Notification",
    customerName: "Customer Name",
    searchCustomers: "Search customers...",
    refresh: "Refresh",
    addCustomer: "Add Customer",
    noCustomers: "No customers found",
    noSearchResults: "No customers match your search",
    customersTableDesc: "Manage and view all customer information",
    customersTable: "Customers Table",
    loading: "Loading...",
    tryAgain: "Try Again",
    errorFetchingScales: "Failed to fetch scales data. Please try again later.",
    errorUpdatingScale: "Failed to update scale. Please try again later.",
    productsManagement: "Products@Customers",
    addProduct: "Add Product",
    
    selectCustomer: "Select Customer",
    updateProduct: "Update Product",
    linkedScales: "Linked Scales",
    unassigned: "Unassigned",
    
    editCustomer: "Edit Customer",
    deleteCustomer: "Delete Customer",
    customerNameEn: "Customer Name (English)",
    customerNameHe: "Customer Name (Hebrew)",
    scalesManagement: "Scales Management",
    
    phone: "Phone",
    address: "Address",
    cancel: "Cancel",
    add: "Add",
    update: "Update",
    
    deleteConfirmation: "Are you sure you want to delete this customer?",
    deleteConfirmationDesc: "This action cannot be undone.",
    confirm: "Confirm",
    customerDeleted: "Customer deleted successfully",
    customerAdded: "Customer added successfully",
    customerUpdated: "Customer updated successfully",
    errorFetchingCustomers: "Failed to fetch customers",
    vendors: {
      title: "Vendor Management",
      addVendor: "Add Vendor",
      editVendor: "Edit Vendor",
      deleteVendor: "Delete Vendor",
      vendorName: "Vendor Name",
      contactPerson: "Contact Person",
      email: "Email",
      phone: "Phone",
      address: "Address",
      cancel: "Cancel",
      add: "Add",
      update: "Update",
      saving: "Saving...",
      deleteConfirmation: "Are you sure you want to delete this vendor?",
      deleteConfirmationDesc: "This action cannot be undone.",
      vendorAdded: "Vendor added successfully",
      vendorUpdated: "Vendor updated successfully",
      vendorDeleted: "Vendor deleted successfully",
      errorFetchingVendors: "Failed to fetch vendors",
      errorAddingVendor: "Failed to add vendor",
      errorUpdatingVendor: "Failed to update vendor",
      errorDeletingVendor: "Failed to delete vendor",
      noVendors: "No vendors found",
    },
    // Registration
    registering: "Registering...",
    
    allFieldsRequired: "All fields are required",
    
    passwordTooShort: "Password must be at least 8 characters",
    passwordNeedsUpper: "Password must contain an uppercase letter",
    passwordNeedsLower: "Password must contain a lowercase letter",
    passwordNeedsNumber: "Password must contain a number",
    passwordNeedsSpecial: "Password must contain a special character",
    passwordsDontMatch: "Passwords don't match",

    // Email Verification
    verifyEmail: "Verify Email",
    verificationCode: "Verification Code",
    verify: "Verify",
    verifying: "Verifying...",
    resendCode: "Resend Code",
    resendingCode: "Resending code...",
    verificationEmailSent: "We've sent a verification code to your email",
    verificationError: "Failed to verify email",
    codeSentSuccessfully: "Verification code sent successfully",
    errorResendingCode: "Failed to resend verification code",
    emailVerified: "Email verified successfully",

    // Password Reset
    resetPassword: "Reset Password",
    newPassword: "New Password",
    resetPasswordError: "Failed to reset password",
    passwordResetSuccess: "Password reset successful",
    sendResetCode: "Send Reset Code",
    sendingResetCode: "Sending reset code...",
    resetCodeSent: "Password reset code sent to your email",
    confirmResetCode: "Confirm Reset Code",
    passwordChanged: "Password changed successfully",

    // Error Messages
    invalidEmail: "Please enter a valid email address",
    networkError: "Network error. Please check your connection",
    unknownError: "An unknown error occurred",
    sessionExpired: "Your session has expired. Please sign in again",
    invalidCode: "Invalid verification code",
    tooManyAttempts: "Too many attempts. Please try again later",

    // Success Messages
    loginSuccess: "Signed in successfully",
    logoutSuccess: "Signed out successfully",
    registrationSuccess: "Registration successful. Please verify your email",

    // Loading States
    loading: "Loading...",
    processing: "Processing...",
    pleaseWait: "Please wait...",
    verifyingEmail: "Verifying your email address...",
    emailVerified: "Email Verified!",
    emailVerifiedDesc: "Your email has been successfully verified. You can now sign in to your account.",
    verificationFailed: "Verification Failed",
    missingVerificationParams: "Invalid verification link. Missing required parameters.",
    emailNotFound: "Email address not found in verification link.",
    requestNewCode: "Request New Code",
    resendingCode: "Resending Code...",
    newCodeSent: "New Code Sent!",
    checkEmailForCode: "A new verification code has been sent to your email address. Please check your inbox.",
    continueToApp: "Continue to App",
    verificationLinkExpired: "Verification link has expired. Please request a new one.",
    invalidVerificationCode: "Invalid verification code. Please try again.",
    sharedProducts: "Products from Vendors",
    sharedProductsDesc: "View products that have been shared with you",
    noSharedProducts: "No products have been shared with you yet",

    // In src/translations/translations.js, add these to both 'en' and 'he' objects:

  // ... existing translations ...
  landing: {
    hero: {
      signIn: "Sign In",
        title: "Smart Inventory Management through Precision Weight Monitoring",
        subtitle: "Take control of your inventory with real-time weight-based monitoring",
        getStarted: "Get Started for Free",
    },
    features: {
      title: "Powerful Features for Modern Inventory Management",
      realTimeMonitoring: {
        title: "Real-Time Monitoring",
        description: "Track inventory levels across all locations in real-time with precise weight-based measurements"
      },
      analytics: {
        title: "Advanced Analytics",
        description: "Make data-driven decisions with comprehensive analytics and trend analysis"
      },
      smartAlerts: {
        title: "Smart Alerts",
        description: "Get instant notifications when inventory levels reach custom thresholds"
      },
      mobileAccess: {
        title: "Mobile Access",
        description: "Monitor your inventory from anywhere using our responsive web application"
      }
    },
    benefits: {
      title: "Why Choose Quantifyz?",
      globalAccess: {
        title: "Global Accessibility",
        description: "Access your inventory data from anywhere in the world"
      },
      security: {
        title: "Secure & Reliable",
        description: "Enterprise-grade security with 99.9% uptime guarantee"
      },
      support: {
        title: "24/7 Support",
        description: "Round-the-clock customer support to help you succeed"
      }
    },
    cta: {
      title: "Ready to Transform Your Inventory Management?",
      button: "Start Your Journey"
    },
    footer: {
      slogan: "Smart Inventory Solutions",
      rights: "© 2024 Quantifyz. All rights reserved.",
      privacy: "Privacy Policy",
      terms: "Terms of Service"
    },
    backToHome: "← Back to home"
  }

  },
  he: {
    // General
    dashboard: "לוח בקרה",
    customers: "לקוחות",
    customersDashboard: "לוח בקרת לקוחות",
    notifications: "התראות",
    allScales: "כל המשקלים",
    home: "בית",
    currentStatus: "מצב נוכחי",
    thresholds: "ספי התראה",
    weightHistory: "היסטוריית משקל",
    save: "שמור שינויים",
    close: "סגור",
    from: "מתאריך",
    to: "עד תאריך",

    // Landing Page
    welcomeTitle: "Quntifyz - מערכת ניטור משקל",
    welcomeSubtitle: "בחר מודול להתחלה",
    dashboardDesc: "צפה בכל המשקלים ובסטטוס הנוכחי שלהם בלוח בקרה מקיף",
    customersDesc: "נהל ועקוב אחר משקלי הלקוחות, המוצרים ורמות המלאי",
    notificationsDesc: "בדוק התראות והודעות על רמות מלאי נמוכות",
    allScalesDesc: "נטר ונהל את כל המשקלים בכל הלקוחות בתצוגה אחת",

    // Dashboard
    scaleMonitorDashboard: "לוח בקרת משקלים",
    latestWeight: "משקל אחרון",
    upperThreshold: "סף עליון",
    lowerThreshold: "סף תחתון",
    noData: "אין נתונים זמינים",

    // Notifications
    scaleAlerts: "התראות משקל",
    noAlerts: "אין התראות להצגה",
    runningLowMessage: "עומד להיגמר לי ה - ",
    productLeft: "נשאר לי רק ",
    pleaseResupply: "!אני צריך קפה",

    // Thresholds
    thresholdSettings: "הגדרות ספי התראה",
    upperThresholdKg: "(ק\"ג) סף עליון",
    lowerThresholdKg: "(ק\"ג) סף תחתון",
    upperNotification: "התראת סף עליון",
    lowerNotification: "התראת סף תחתון",
    whatsappNumber: "מספר וואטסאפ",
    messageWhenExceeds: "הודעה כאשר המשקל עובר את הסף העליון",
    messageWhenBelow: "הודעה כאשר המשקל יורד מתחת לסף התחתון",

    // Customer Dashboard
    customerOverview: "סקירת לקוחות",
    backToCustomers: "חזרה ללקוחות",
    // Menu
    menuTitle: "Qunatifyz",
    menuSubtitle: "שקול!",
    version: "גרסה",
    copyright: "© 2024 ניטור משקל",

    // Menu Descriptions
    homeDesc: "חזרה לדף הבית",
    userName: "משתמש",
    welcomeMessage: "{time}, {name}",
    timeGreetings: {
      morning: "בוקר טוב",
      afternoon: "צהריים טובים",
      evening: "ערב טוב"
    },

    stats: {
      activeScales: "משקלים פעילים",
      customers: "לקוחות פעילים",
      alerts: "התראות פעילות",
      today: "היום",
      thisWeek: "השבוע",
      thisMonth: "החודש"
    },

    quickActions: {
      title: "פעולות מהירות",
      items: [
        "הוסף משקל חדש",
        "הפק דוח",
        "צפה בהתראות"
      ]
    },

    // Menu Cards
    saving: "...שומר",
    saveChanges: "שמור שינויים",
    weight: "משקל",
    lastUpdated: "עדכון אחרון",
    customer: "לקוח",
    productName: "שם מוצר",
    customersTable: "טבלת לקוחות",

    // Graph
    time: "זמן",

    // Notifications
    notificationSettings: "הגדרות התראות",
    phoneNumber: "מספר טלפון",
    message: "הודעה",
    upperThresholdNotification: "התראת סף עליון",
    lowerThresholdNotification: "התראת סף תחתון",
    customerName: "שם לקוח",
    searchCustomers: "חיפוש לקוחות...",
    refresh: "רענון",
    addCustomer: "הוסף לקוח",
    noCustomers: "לא נמצאו לקוחות",
    noSearchResults: "לא נמצאו לקוחות התואמים את החיפוש",
    customersTableDesc: "ניהול וצפייה במידע של כל הלקוחות",
    refresh: "רענן",
    loading: "...טוען משקלים",
    tryAgain: "נסה שוב",
    noData: "אין משקלים זמינים",
    errorFetchingScales: "שגיאה בטעינת נתוני המשקלים. אנא נסה שוב מאוחר יותר.",
    errorUpdatingScale: "שגיאה בעדכון המשקל. אנא נסה שוב מאוחר יותר.",
    productsManagement: "מוצרים אצל לקוחות",
    addProduct: "הוסף מוצר",
    productName: "שם המוצר",
    selectCustomer: "בחר לקוח",
    updateProduct: "עדכן מוצר",
    linkedScales: "משקלים מקושרים",
    unassigned: "לא משויך",
    addCustomer: "הוסף לקוח",
    editCustomer: "ערוך לקוח",
    deleteCustomer: "מחק לקוח",
    customerNameEn: "שם לקוח (אנגלית)",
    customerNameHe: "שם לקוח (עברית)",
    email: "דוא\"ל",
    phone: "טלפון",
    address: "כתובת",
    cancel: "ביטול",
    add: "הוסף",
    update: "עדכן",
    saving: "שומר...",
    deleteConfirmation: "האם אתה בטוח שברצונך למחוק לקוח זה?",
    deleteConfirmationDesc: "לא ניתן לבטל פעולה זו.",
    confirm: "אישור",
    customerDeleted: "הלקוח נמחק בהצלחה",
    customerAdded: "הלקוח נוסף בהצלחה",
    customerUpdated: "הלקוח עודכן בהצלחה",
    errorFetchingCustomers: "שגיאה בטעינת רשימת הלקוחות", // For Hebrew
    vendors: {
      title: "ניהול ספקים",
      addVendor: "הוסף ספק",
      editVendor: "ערוך ספק",
      deleteVendor: "מחק ספק",
      vendorName: "שם ספק",
      contactPerson: "איש קשר",
      email: "דוא\"ל",
      phone: "טלפון",
      address: "כתובת",
      cancel: "ביטול",
      add: "הוסף",
      update: "עדכן",
      saving: "שומר...",
      deleteConfirmation: "האם אתה בטוח שברצונך למחוק ספק זה?",
      deleteConfirmationDesc: "לא ניתן לבטל פעולה זו.",
      vendorAdded: "הספק נוסף בהצלחה",
      vendorUpdated: "הספק עודכן בהצלחה",
      vendorDeleted: "הספק נמחק בהצלחה",
      errorFetchingVendors: "שגיאה בטעינת נתוני הספקים",
      errorAddingVendor: "שגיאה בהוספת ספק",
      errorUpdatingVendor: "שגיאה בעדכון ספק",
      errorDeletingVendor: "שגיאה במחיקת ספק",
      noVendors: "לא נמצאו ספקים",
    },
    productsDashboard: "לוח מוצרים",
    productStatus: "סטטוס מוצר",
    upperThreshold: "סף עליון",
    lowerThreshold: "סף תחתון",
    lastUpdated: "עודכן לאחרונה",
    noProducts: "לא נמצאו מוצרים",
    failedToFetchProducts: "נכשל בטעינת נתוני המוצרים",
    signIn: "התחברות",
    signOut: "התנתקות",
    register: "הרשמה",
    forgotPassword: "שכחת סיסמה?",
    email: 'דוא"ל',
    password: "סיסמה",
    confirmPassword: "אימות סיסמה",
    name: "שם",
    createAccount: "יצירת חשבון",
    alreadyHaveAccount: "כבר יש לך חשבון? התחבר",
    dontHaveAccount: "אין לך חשבון? הירשם",
    verifyEmail: 'אימות דוא"ל',
    verificationCode: "קוד אימות",
    verify: "אמת",
    resendCode: "שלח קוד מחדש",
    verificationEmailSent: 'שלחנו קוד אימות לדוא"ל שלך',
    passwordsDontMatch: "הסיסמאות אינן תואמות",
    passwordRequirements: "הסיסמה חייבת להכיל לפחות 8 תווים",
    signInError: "שגיאה בהתחברות",
    signUpError: "שגיאה ביצירת החשבון",
    verificationError: 'שגיאה באימות הדוא"ל',
    resetPassword: "איפוס סיסמה",
    newPassword: "סיסמה חדשה",
    resetPasswordError: "שגיאה באיפוס הסיסמה",
    passwordResetSuccess: "הסיסמה אופסה בהצלחה",
    welcomeBack: "ברוך שובך",
    welcome: "ברוכים הבאים למערכת Scale Monitor",
    // Authentication - General
    signIn: "התחברות",
    signOut: "התנתקות",
    register: "הרשמה",
    forgotPassword: "שכחת סיסמה?",
    email: 'דוא"ל',
    password: "סיסמה",
    confirmPassword: "אימות סיסמה",
    name: "שם",
    createAccount: "יצירת חשבון",
    alreadyHaveAccount: "כבר יש לך חשבון? התחבר",
    dontHaveAccount: "אין לך חשבון? הירשם",
    welcomeBack: "ברוך שובך",
    welcome: "ברוכים הבאים למערכת Scale Monitor",

    // Registration
    registering: "נרשם...",
    signUpError: "שגיאה ביצירת החשבון",
    allFieldsRequired: "כל השדות נדרשים",
    passwordRequirements: "הסיסמה חייבת לעמוד בדרישות הבאות:",
    passwordTooShort: "הסיסמה חייבת להכיל לפחות 8 תווים",
    passwordNeedsUpper: "הסיסמה חייבת להכיל אות גדולה",
    passwordNeedsLower: "הסיסמה חייבת להכיל אות קטנה",
    passwordNeedsNumber: "הסיסמה חייבת להכיל מספר",
    passwordNeedsSpecial: "הסיסמה חייבת להכיל תו מיוחד",
    passwordsDontMatch: "הסיסמאות אינן תואמות",

    // Email Verification
    verifyEmail: 'אימות דוא"ל',
    verificationCode: "קוד אימות",
    verify: "אמת",
    verifying: "מאמת...",
    resendCode: "שלח קוד מחדש",
    resendingCode: "שולח קוד מחדש...",
    verificationEmailSent: 'שלחנו קוד אימות לדוא"ל שלך',
    verificationError: 'שגיאה באימות הדוא"ל',
    codeSentSuccessfully: "קוד האימות נשלח בהצלחה",
    errorResendingCode: "שגיאה בשליחת קוד האימות מחדש",
    emailVerified: 'הדוא"ל אומת בהצלחה',

    // Password Reset
    resetPassword: "איפוס סיסמה",
    newPassword: "סיסמה חדשה",
    resetPasswordError: "שגיאה באיפוס הסיסמה",
    passwordResetSuccess: "הסיסמה אופסה בהצלחה",
    sendResetCode: "שלח קוד איפוס",
    sendingResetCode: "שולח קוד איפוס...",
    resetCodeSent: "קוד איפוס הסיסמה נשלח לדוא\"ל שלך",
    confirmResetCode: "אשר קוד איפוס",
    passwordChanged: "הסיסמה שונתה בהצלחה",

    // Error Messages
    invalidEmail: "נא להזין כתובת דוא\"ל תקינה",
    networkError: "שגיאת רשת. נא לבדוק את החיבור",
    unknownError: "אירעה שגיאה לא ידועה",
    sessionExpired: "פג תוקף החיבור. נא להתחבר מחדש",
    invalidCode: "קוד אימות לא תקין",
    tooManyAttempts: "יותר מדי ניסיונות. נא לנסות שוב מאוחר יותר",

    // Success Messages
    loginSuccess: "התחברת בהצלחה",
    logoutSuccess: "התנתקת בהצלחה",
    registrationSuccess: "ההרשמה הושלמה בהצלחה. נא לאמת את הדוא\"ל",

    // Loading States
    loading: "טוען...",
    processing: "מעבד...",
    pleaseWait: "נא להמתין...",
    scalesManagement: "ניהול משקלים",
    verifyingEmail: "מאמת את כתובת האימייל שלך...",
    emailVerified: "האימייל אומת!",
    emailVerifiedDesc: "כתובת האימייל שלך אומתה בהצלחה. כעת תוכל להתחבר לחשבונך.",
    verificationFailed: "האימות נכשל",
    missingVerificationParams: "קישור אימות לא חוקי. חסרים פרמטרים נדרשים.",
    emailNotFound: "כתובת האימייל לא נמצאה בקישור האימות.",
    requestNewCode: "בקש קוד חדש",
    resendingCode: "שולח קוד חדש...",
    newCodeSent: "קוד חדש נשלח!",
    checkEmailForCode: "קוד אימות חדש נשלח לכתובת האימייל שלך. אנא בדוק את תיבת הדואר הנכנס.",
    continueToApp: "המשך לאפליקציה",
    verificationLinkExpired: "קישור האימות פג תוקף. אנא בקש קישור חדש.",
    invalidVerificationCode: "קוד אימות לא חוקי. אנא נסה שוב.",
    sharedProducts: "מוצרים מספקים",
    sharedProductsDesc: "צפה במוצרים ששותפו איתך",
    noSharedProducts: "טרם שותפו איתך מוצרים",
    // In src/translations/translations.js, add these to both 'en' and 'he' objects:

  // ... existing translations ...
  landing: {
    hero: {
      title: "מערכת ניטור משקל Quantifyz",
      subtitle: "ניהול מלאי חכם באמצעות ניטור משקל מדויק",
      getStarted: "התחל עכשיו",
      signIn: "התחבר",
    },
    features: {
      title: "תכונות חזקות לניהול מלאי מודרני",
      realTimeMonitoring: {
        title: "ניטור בזמן אמת",
        description: "עקוב אחר רמות המלאי בכל המיקומים בזמן אמת עם מדידות מדויקות מבוססות משקל"
      },
      analytics: {
        title: "ניתוח מתקדם",
        description: "קבל החלטות מבוססות נתונים עם ניתוח מקיף וניתוח מגמות"
      },
      smartAlerts: {
        title: "התראות חכמות",
        description: "קבל התראות מיידיות כאשר רמות המלאי מגיעות לספים מותאמים אישית"
      },
      mobileAccess: {
        title: "גישה מהנייד",
        description: "נטר את המלאי שלך מכל מקום באמצעות האפליקציה"
      }
    },
    benefits: {
      title: "למה לבחור ב-Quantifyz?",
      globalAccess: {
        title: "נגישות גלובלית",
        description: "גש לנתוני המלאי שלך מכל מקום בעולם"
      },
      security: {
        title: "מאובטח ואמין",
        description: "אבטחה ברמה ארגונית עם 99.9% זמינות מובטחת"
      },
      support: {
        title: "תמיכה 24/7",
        description: "תמיכת לקוחות מסביב לשעון כדי לעזור לך להצליח"
      }
    },
    cta: {
      title: "מוכנים לשנות את ניהול המלאי שלכם?",
      button: "התחל את המסע"
    },
    footer: {
      slogan: "פתרונות מלאי חכמים",
      rights: "© 2024 Quantifyz. כל הזכויות שמורות.",
      privacy: "מדיניות פרטיות",
      terms: "תנאי שימוש"
    },
    backToHome: "← חזרה לדף הבית"
  }
  }
};